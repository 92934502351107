// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
// require("channels")

import jQ from "jquery";
global.$ = jQ;
window.jQuery = $;

import "bootstrap";
// Library initializers
import "../plugins/init_flatpickr";

import 'stylesheets/application'

// Sidecar assets
import "../components";


import { initSelect2 } from "../plugins/init_select2";
import { addSmoothScroll } from "packs/add_smoothscroll"
import { sidebarToggle } from "packs/sidebar_toggle"

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Added this in order to use jquery in js.erb files under views

document.addEventListener("turbolinks:load", function () {

        // Select2
        initSelect2();

        // Smoothscroll
        addSmoothScroll();

        // Bootstrap popover
        // $(function () {
            $('[data-bs-toggle="popover"]').popover()
            $('[data-bs-toggle="tooltip"]').tooltip()
        // })

        const sidebarToggler = document.querySelector('#sidebarToggle')

        if (sidebarToggler) {
                sidebarToggle()
        }
})
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
