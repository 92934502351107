const sidebarToggle = () => {
    
    const buttonToggle = document.querySelector('#sidebarToggle')

    buttonToggle.addEventListener('click', e => {
        e.preventDefault()
        document.body.classList.toggle('sb-sidenav-toggled')
    })    

}

export { sidebarToggle }